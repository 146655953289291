import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { lazy as loadable } from '@loadable/component'

import * as ReduxConfiguration from './redux-configuration'
import * as Logger from './logger'
import * as ReduxSession from './redux-session'
import * as ReduxPlayer from './redux-player'
import * as Suspense from './suspense'
import { AppDrawers } from './drawers/app-drawers'
import { Canonical } from './canonical'
import { CookieConsentBanner } from './cookie-consent-banner'
import { ErrorFallback } from './error-fallback'
import { Fonts } from './fonts'
import { Footer } from './footer'
import { GoogleTagManager } from './gtm'
import { Header } from './header'
import { LocaleRedirect } from './locale-redirect'
import { NotificationsContainer } from './notifications-container'
import { PageContent } from './page-content'
import { Routes } from './routes'
import { SeoMetaDataContainer } from './seo-meta-data'
import { useCssCustomProperties } from './use-css-custom-properties'
import { useLazyloadInsideWindowCheck } from './use-lazyload-inside-window-check'
import { useNewPromotionListener } from './use-new-promotions-listener'
import { usePageview } from './use-pageview'
import { useSessionListener } from './use-session-listener'
import { useWebSocketListener } from './use-websocket-listener'

const SupportChat = loadable(() => import('./support-chat'), { ssr: false })

export function App() {
  useWebSocketListener()
  useCssCustomProperties()
  useSessionListener()
  usePageview()
  useNewPromotionListener()
  useLazyloadInsideWindowCheck()

  return (
    <LocaleRedirect>
      <Fonts />

      <Header />

      <PageContent>
        <Suspense.Boundary>
          <Suspense.Boundary fallback={null}>
            <SeoMetaDataContainer />
          </Suspense.Boundary>

          <ErrorBoundary
            onError={(error) => {
              if (!process.browser || process.env.NODE_ENV !== 'production') {
                Logger.react.error(error)
              }
            }}
            onReset={() => {
              window.location = '/'
            }}
            FallbackComponent={ErrorFallback}
          >
            <Routes />
          </ErrorBoundary>
        </Suspense.Boundary>
      </PageContent>

      <Suspense.Boundary>
        <Footer />
      </Suspense.Boundary>
      <AppDrawers />

      <NotificationsContainer />
      <CookieConsentBanner />

      <GoogleTagManager />
      <ReduxSession.KeepReduxSessionUpdated />
      <ReduxConfiguration.KeepReduxConfigurationUpdated />
      <ReduxPlayer.KeepReduxPlayerUpdated />
      <Suspense.Boundary fallback={null}>
        <SupportChat />
      </Suspense.Boundary>
      <Canonical />
    </LocaleRedirect>
  )
}
